import React, { useEffect, useState } from 'react';
import "./Crud.css"
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const Crud = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [userid, setUserid] = useState(null);

    useEffect(() => {
        fetchData()
    }, []
    );

    const fetchData = () => {
        fetch(' ')
            .then((response) => response.json())
            .then((result) => {
                setData(result);

            })
    };

    const handleCreate = () => {
        let newData = {
            name: name,
            email: email,
        };
        fetch('https://657459bcf941bda3f2afa145.mockapi.io/user/crud', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"

            },
            body: JSON.stringify(newData),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                setData([...data, result])
                resertForm();
            })
    };
    const handleUpdate = () => {
        let updateData = {
            name: name,
            email: email,
        }
        fetch(`https://657459bcf941bda3f2afa145.mockapi.io/user/crud/${userid}`, {
            method: "PUT",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(updateData),
        })
            .then((response) => response.json())
            .then((result) => {
                setData(data.map((item) => (item.id === userid ? result : item)));
                resertForm();
            })
    };

    const handleEdit = (id) => {
        const userid = data.find((item) => item.id === id);
        setUserid(id);
        setName(userid.name);
        setEmail(userid.email);
    };


    const handleDelete = (id) => {
        fetch(`https://657459bcf941bda3f2afa145.mockapi.io/user/crud/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                setData(data.filter((item) => item.id !== id));
                resertForm();
            })
    }

    const handleChange = (e) => {
        if (e.target.id === 'name') {
            setName(e.target.value)
        } else if (e.target.id === 'email') {
            setEmail(e.target.value);
        }
    };

    const resertForm = () => {
        setName('');
        setEmail('');
        setUserid(null)
    }


    return (
        <center>
            
      
            <div className='Container'>
           
                <div className='box'>
                    <div className='form'><br />
                        <input type='text' id='name' placeholder='Enter Name' name='name' value={name} onChange={handleChange} /><br /><br />
                        <input type='text' id='email' placeholder='Enter Email' name='email' value={email} onChange={handleChange} /><br></br>
                        {userid ? (
                            <Button variant='contained' onClick={handleUpdate}>Update</Button>
                        ) : (


                            <Button variant='contained'color="success" onClick={handleCreate}startIcon={<AddCircleRoundedIcon />}>Add</Button>

                        )

                        }

                    </div>
                </div>
                <div className='result'>
                    <table>
                        <thead>
                            
                            {data.map((item)=>(
                                <tr key={item.id}>
                                    <td className='head'>  {item.name}<img src='/Images/logo.png' alt="logo"  width="20px"/>{item.email}</td>



                                    <Button variant='contained' onClick={() => handleEdit(item.id)}startIcon={<EditIcon />}>Edit</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;

                                       

                                    <Button variant='contained' onClick={() => handleDelete(item.id)}startIcon={<DeleteIcon />}>Delete</Button>
                                </tr>
                            ))}

                        </thead>
                    </table>



                </div>
            </div>
        </center>
    )
}

export default Crud