import React from "react";
// import Crud************* from "../src/Components/Crud"
import "./App.css";
import Crud from "./Components/Crud";



function App() {




  return (


    <div className="App">

<Crud />





    </div>
  )
};


export default App;
